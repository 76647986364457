import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

const router = new Router({
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/login',
			alias: '/',
			name: 'Login',
			component: () => import('@/views/Login.vue' /* webpackChunkName: "chunk-login" */),
		},
		{
			path: '/reset-password/:token',
			name: 'ResetPassword',
			component: () => import('@/views/ResetPassword.vue'),
		},

		{
			path: '/crossroads',
			name: 'Crossroads',
			meta: { requiresAuth: true },
			component: () => import('@/views/Crossroads.vue'),
		},
		{
			path: '/clients',
			name: 'ClientDashboard',
			meta: { requiresAuth: true },
			component: () => import('@/views/clients/ClientDashboard.vue'),
		},
		{
			path: '/clients/select',
			name: 'SelectClient',
			meta: { requiresAuth: true },
			component: () => import('@/views/clients/SelectClient.vue'),
		},

		{
			path: '/clients/profile',
			// name: 'Profile',
			meta: { requiresAuth: true, requiresClient: true },
			component: () => import('@/views/clients/Profile.vue'),
			children: [
				{
					path: 'details/',
					name: 'ProfileDetailsStart',
					redirect: { name: 'ProfileDetails', params: { section: 'personal' } },
				},
				{
					path: 'details/:section',
					name: 'ProfileDetails',
					props: true,
					component: () => import('@/views/clients/ProfileDetails.vue'),
				},
				{
					path: 'relatives/',
					name: 'ProfileRelatives',
					component: () => import('@/views/clients/ProfileRelatives.vue'),
				},
				{
					path: 'finances/',
					name: 'ProfileFinancesStart',
					redirect: { name: 'ProfileFinances', params: { section: 'assets' } },
				},
				{
					path: 'finances/:section',
					name: 'ProfileFinances',
					props: true,
					component: () => import('@/views/clients/ProfileFinances.vue'),
				},
				{
					path: 'photos',
					name: 'ProfilePhotos',
					component: () => import('@/views/clients/ProfilePhotos.vue'),
				},
				{
					path: 'photos/list',
					name: 'ProfilePhotosList',
					component: () => import('@/views/clients/ProfilePhotosList.vue'),
				},
				{
					path: 'documents',
					name: 'ProfileDocuments',
					component: () => import('@/views/clients/ProfileDocuments.vue'),
				},
				{
					path: 'summary',
					name: 'ProfileSummaryStart',
					redirect: { name: 'ProfileSummary', params: { section: 'new' } },
				},
				{
					path: 'summary/:section',
					name: 'ProfileSummary',
					props: true,
					component: () => import('@/views/clients/ProfileSummary.vue'),
				},
			],
		},

		{
			path: '/clients/recommendations',
			// name: 'Recommendations',
			meta: { requiresAuth: true, requiresClient: true },
			component: () => import('@/views/clients/Recommendations.vue'),
			children: [
				{
					path: '',
					name: 'RecommendationsCreate',
					component: () => import('@/views/clients/RecommendationsCreate.vue'),
				},
				{
					path: 'list/',
					name: 'RecommendationsList',
					component: () => import('@/views/clients/RecommendationsList.vue'),
				},
				{
					path: 'edit/:id',
					name: 'RecommendationsEdit',
					props: true,
					component: () => import('@/views/clients/RecommendationsEdit.vue'),
				},

			],
		},
		{
			path: '/clients/wem-story',
			name: 'WemStory',
			meta: { requiresAuth: true },
			component: () => import('@/views/clients/WemStory.vue'),
		},
		{
			path: '/clients/drawings/create',
			name: 'DrawingsCreate',
			meta: { requiresAuth: true, requiresClient: true },
			component: () => import('@/views/clients/DrawingsCreate.vue'),
		},
		{
			path: '/clients/drawings/list',
			name: 'DrawingsList',
			meta: { requiresAuth: true, requiresClient: true },
			component: () => import('@/views/clients/DrawingsList.vue'),
		},
		{
			path: '/clients/docsign',
			name: 'DocSign',
			component: () => import('@/views/clients/DocSign.vue'),
		},

		{
			path: '/office',
			name: 'Office',
			meta: { requiresAuth: true },
			component: () => import('@/views/office/Office.vue'),
		},
		{
			path: '/office/reporting',
			// name: 'Reporting',
			meta: { requiresAuth: true },
			component: () => import('@/views/office/Reporting.vue'),
			children: [
				{
					path: '',
					name: 'ReportingOverview',
					component: () => import('@/views/office/ReportingOverview.vue'),
				},
				{
					path: 'summary/',
					name: 'ReportingSummary',
					component: () => import('@/views/office/ReportingSummary.vue'),
				},
				{
					path: 'create/',
					name: 'ReportingCreate',
					component: () => import('@/views/office/ReportingCreate.vue'),
				},
			],
		},
		{
			path: '/office/calendar',
			name: 'Calendar',
			meta: { requiresAuth: true },
			component: () => import('@/views/office/Calendar.vue'),
		},
		{
			path: '/documents/:category',
			name: 'Documents',
			props: true,
			meta: { requiresAuth: true },
			component: () => import('@/views/Documents.vue'),
		},
		{
			path: '/account/edit',
			name: 'EditAccount',
			meta: { requiresAuth: true },
			component: () => import('@/views/EditAccount.vue'),
		},

		{
			path: '/manage/clients',
			name: 'ManageClients',
			meta: { requiresAuth: true },
			component: () => import('@/views/manage/ManageClients.vue'),
		},
		{
			path: '/manage/users',
			name: 'ManageUsers',
			meta: { requiresAuth: true },
			component: () => import('@/views/manage/ManageUsers.vue'),
		},
	],
});

// Navigation Guard
router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	const requiresClient = to.matched.some((record) => record.meta.requiresClient);

	if (requiresAuth && !store.getters['me/isLoggedIn']) {
		next({ name: 'Login' });
	} else if (requiresClient && !store.getters['clients/isClientSelected']) {
		next({ name: 'SelectClient' });
	} else if (to.name === 'Login' && store.getters['me/isLoggedIn']) {
		next({ name: 'Crossroads' });
	} else {
		next();
	}
});

export default router;
