<template>
	<button
		class="icon-button"
		@click="$emit('click', $event)"
	>
		<FontAwesomeIcon :icon="icon" class="icon"/>
		<span v-if="label" class="label">
			{{ label }}
		</span>
	</button>
</template>

<script>
export default {
	name: 'BaseButton',
	props: {
		icon: String,
		label: String,
	},
};
</script>

<style lang="scss" scoped>
@import '@/scss/global';

.icon-button {
	@include reset-button;
	color: $gray-500;
	padding: 0.625rem 0.75rem;
	min-width: 3.5rem;
	text-align: center;
	.icon,
	.label {
		vertical-align: middle;
		margin: 0 0.1875rem;
	}
	.icon {
		font-size: 1.125rem;
	}
	.label {
		text-transform: uppercase;
		font-size: 0.75rem;
		font-weight: 700;
		letter-spacing: 0.1em;
	}
	&:hover {
		background-color: $gray-100;
	}
	& + & {
		border-left: 1px solid $gray-400;
	}
}

</style>
