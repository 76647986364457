import api from '@/api/';
import { startOfWeek, parseISO, formatISO } from 'date-fns';
import i18n from '@/i18n';

const initialState = {
	selectedWeek: null,
	selectedDay: null,
	overviewWeekData: {},
	overviewDayData: [],
	events: [
		{
			date: '2019-09-02',
			type: 'cp',
			name: 'Janka',
			notes: 'testovací event',
		},
		{
			date: '2019-09-02',
			type: 'wp',
			name: 'Zuzka',
			notes: 'iný testovací event',
		},
		{
			date: '2019-09-03',
			type: 'wp',
			name: 'Mirka',
			notes: 'ďalší testovací event',
		},
	],
	eventTypeCodes: [], // cp, wp, service
	viewingAs: '',

	summaryQuery: {
		from: '',
		to: '',
	},
	summaryData: [],
};

export default {
	namespaced: true,
	state: { ...initialState },
	mutations: {
		addEvent(state, payload) {
			state.events.push(payload);
		},
		setEvents(state, payload) {
			state.events = payload;
		},
		setViewingAs(state, payload) {
			state.viewingAs = payload;
		},

		setSummaryQuery(state, payload) {
			state.summaryQuery = payload;
		},
		setSummaryData(state, payload) {
			state.summaryData = payload;
		},

		setSelectedWeek(state, payload) {
			const dateObject = startOfWeek(parseISO(payload), { weekStartsOn: 1 });
			const dateString = formatISO(dateObject, { representation: 'date' });
			state.selectedWeek = dateString;
		},
		setSelectedDay(state, payload) {
			state.selectedDay = payload;
		},

		setOverviewWeekData(state, payload) {
			state.overviewWeekData = payload;
		},
		setOverviewDayData(state, payload) {
			state.overviewDayData = payload;
		},

		setEventTypeCodes(state, payload) {
			state.eventTypeCodes = payload;
		},
		clearState(state) {
			Object.assign(state, initialState);
		},
	},
	actions: {
		createEvent(context, payload) {
			// commit('addEvent', payload);
			return api.request({
				url: '/reports/events/',
				method: 'post',
				data: payload,
			})
				.then((response) => response.data);
		},
		fetchOverview({ state, commit }) {
			const params = {
				week: state.selectedWeek,
			};
			if (state.viewingAs) {
				params.user = state.viewingAs;
			}
			return api.request({
				url: '/reports/overview/',
				method: 'get',
				params,
			})
				.then(({ data }) => {
					commit('setOverviewWeekData', data);
					return true;
				});
		},
		fetchEvents({ state, commit }) {
			const params = {
				date: state.selectedDay,
			};
			if (state.viewingAs) {
				params.user = state.viewingAs;
			}
			return api.request({
				url: '/reports/events/',
				method: 'get',
				params,
			})
				.then(({ data }) => {
					commit('setOverviewDayData', data);
					return true;
				});
		},
		fetchSummary({ commit }, payload) {
			commit('setSummaryQuery', payload);
			return api.request({
				url: '/reports/summary/',
				method: 'get',
				params: payload,
			})
				.then(({ data }) => {
					commit('setSummaryData', data);
					return true;
				});
		},
		fetchEventTypes({ commit }) {
			return api.request({
				url: '/reports/events/types/',
				method: 'get',
			})
				.then((response) => {
					commit('setEventTypeCodes', response.data);
				});
		},
	},
	getters: {
		eventTypes(state, getters) {
			return state.eventTypeCodes.map(
				(code) => ({ value: code, label: getters.translateEventType(code) }),
			);
		},
		eventTypesOptions(state, getters) {
			const options = [...getters.eventTypes];
			options.unshift({ value: null, label: i18n.t('common.choose') });
			return options;
		},
		// eslint-disable-next-line arrow-body-style
		translateEventType: () => (code) => {
			return i18n.te(`eventTypes.${code}`) ? i18n.t(`eventTypes.${code}`) : code;
		},
		// dailyReport(state) {
		// 	if(!state.selectedDay) return null;
		// 	return state.events.filter(event => {
		// 		return event.date === format(state.selectedDay, standardDateFormat)
		// 	})
		// },
		summaryDataTranslated(state, getters) {
			return state.summaryData.map((row) => {
				const rowTranslated = row;
				const match = getters.eventTypes.find((type) => type.value === row.eventType);
				if (match) {
					rowTranslated.eventType = match.label;
				}
				return rowTranslated;
			});
		},
		viewingAsEmail(state, getters, rootState) {
			if (!state.viewingAs) {
				return rootState.me.email;
			}
			const match = rootState.me.teamMembers.find((m) => m.id === state.viewingAs);
			return match ? match.email : null;
		},
	},
};
