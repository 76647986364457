import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import me from './modules/me';
import users from './modules/users';
import clients from './modules/clients';
import recommendations from './modules/recommendations';
import reports from './modules/reports';
import calendar from './modules/calendar';
import documents from './modules/documents';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {

	},
	mutations: {

	},
	actions: {
		clearAll({ commit }) {
			commit('me/clearState');
			commit('clients/clearState');
			commit('recommendations/clearState');
			commit('reports/clearState');
			commit('calendar/clearState');
			return true;
		},
	},
	modules: {
		me,
		users,
		clients,
		recommendations,
		reports,
		calendar,
		documents,
	},
	plugins: [createPersistedState()],
});
