<template>
	<div id="ewem-app">
		<TheAppHeader/>
		<main class="ewem-main">
			<router-view/>
		</main>
	</div>
</template>

<script>
import TheAppHeader from '@/components/TheAppHeader.vue';

export default {
	name: 'App',
	components: {
		TheAppHeader,
	},
	methods: {
		setLanguageFromStore() {
			const { frontendLanguage } = this.$store.state.me;
			if (frontendLanguage) {
				this.$root.$i18n.locale = frontendLanguage;
			}
		},
	},
	mounted() {
		this.setLanguageFromStore();
	},
};
</script>

<style lang="scss">
@import '@/scss/global';

body {
	font-family: 'sofia-pro', sans-serif;
	background-color: $gray-100;
}
#ewem-app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $gray-600;
	* {
		box-sizing: border-box;
		touch-action: manipulation;
	}
	h1, h2, h3, h4 {
		color: $primary;
	}
}
.ewem-main {
	padding: 1rem 1.25rem;
	margin: 0 auto;
}

// Base elements
.narrow-container {
	max-width: 600px;
	margin: 0 auto;
}

.nav-tabs {
	margin-bottom: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 0.75rem;
	a {
		@include reset-link;
		display: inline-block;
		padding: 0.75rem;
		border-radius: $border-radius;
	}
	a:hover {
		background-color: $white;
		box-shadow: $input-shadow;
	}
	a.router-link-exact-active,
	a.active {
		background-color: $gray-500;
		color: $white;
	}
}

.table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 1rem;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: $input-shadow;
	td, th {
		text-align: left;
		border: 0;
		border-bottom: 1px solid $gray-400;
		padding: 10px 12px;
	}
	thead {
		color: $primary;
		text-transform: uppercase;
		font-size: 0.875em;
	}
	tbody > tr:last-of-type > td,
	tbody > tr:last-of-type > th {
		border-bottom: 0;
	}
	&.-fixed {
		table-layout: fixed;
	}
}

.spinner {
	animation: spin 2s linear infinite;
}

// Grid
.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: 0 - $gutter;
	margin-right: 0 - $gutter;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-grow {
	padding: 0 $gutter;
}
.col-1 { flex: 0 0 16.66%; }
.col-2 { flex: 0 0 33.33%; }
.col-3 { flex: 0 0 50%; }
.col-4 { flex: 0 0 66.66%; }
.col-5 { flex: 0 0 83.33%; }
.col-6 { flex: 0 0 100%; }
.col-grow { flex: 1 0 0; }

// External
.toasted-container {
	.toasted.toasted-primary {
		border-radius: $border-radius;
		box-shadow: $input-shadow;
		border: 1px solid rgba(black, 0.5);
		font-weight: 400;
	}
	.toasted.toasted-primary.info {
		background-color: $primary;
	}
}

.modal-content {
	padding: 2rem;
	.title {
		margin-top: 0;
	}
}

@keyframes spin {
	from { transform: rotate(0deg) }
	to { transform: rotate(360deg) }
}

</style>
