<template>
	<header class="the-header" v-if="isLoggedIn">
		<div class="left header-items">
			<IconButton
				@click.native="moveHome()"
				icon="home"
			/>
			<IconButton
				@click.native="moveUpwards()"
				icon="arrow-left"
			/>
			<div v-if="isClientSelected" class="selected-client">
				<span class="label">
					{{ $t('common.client') }}:
				</span>
				<a href="#" @click.prevent="changeClient()" class="name">
					{{ $store.state.clients.selected.name }}
					<span class="caret">&#9660;</span>
				</a>
			</div>
		</div>
		<div class="center">
			<router-link :to="{ name: 'Crossroads' }">
				<img src="@/assets/ewem-logo.svg" class="header-logo">
			</router-link>
			<div
				v-if="headerBadge"
				class="header-badge"
				:title="headerBadge.title"
				:style="{ backgroundColor: headerBadge.color }"
			>
				{{ headerBadge.label }}
			</div>
		</div>
		<div class="right header-items">
			<IconButton
				@click="openDrawing()"
				icon="paint-brush"
			/>
			<IconButton
				v-if="hasButton(buttonAvailable.openCalculator)"
				@click="openCalculator()"
				icon="calculator"
				:label="$t('calculator')"
			/>
			<IconButton
				v-if="hasButton(buttonAvailable.openPrint)"
				@click="openPrint()"
				icon="print"
				:label="$t('common.print')"
			/>
			<IconButton
				v-if="$route.name !== 'Calendar'"
				@click="dropdownToggle('language')"
				icon="language"
			/>
			<IconButton
				v-if="isLoggedIn"
				@click="dropdownToggle('account')"
				icon="user-circle"
			/>
			<div
				class="dropdown-menu"
				v-show="isDropdownVisible.language"
				@click="dropdownHide()"
			>
				<button
					v-for="lang in languages"
					:key="lang.code"
					@click="switchLanguage(lang.code)"
				>
					{{ lang.title }}
				</button>
			</div>
			<div
				class="dropdown-menu"
				v-show="isDropdownVisible.account"
				@click="dropdownHide()"
			>
				<button @click="editAccount()">
					{{ $t('myAccount') }}
				</button>
				<button
					v-if="hasCapability('clients.all')"
					@click="$router.push({ name: 'ManageClients' })"
				>
					{{ $t('sections.manageClients') }}
				</button>
				<button
					v-if="hasCapability('users.manage')"
					@click="$router.push({ name: 'ManageUsers' })"
				>
					{{ $t('sections.manageUsers') }}
				</button>
				<button @click="logout()">
					{{ $t('logout') }}
				</button>
			</div>
		</div>
		<modal name="calculator" height="650" width="500">
			<iframe src="https://www.desmos.com/fourfunction" frameborder="0" width="500" height="650"></iframe>
		</modal>
	</header>
</template>

<script>
import IconButton from '@/components/ui/IconButton.vue';

import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
	name: 'AppHeader',
	data() {
		return {
			buttonAvailable: {
				openCalculator: ['Office'],
				openPrint: ['Office', 'Calendar', 'ReportingOverview', 'ReportingSummary', 'ReportingCreate', 'RecommendationsCreate', 'RecommendationsList'],
			},
			languages: [
				{ code: 'en', title: 'English' },
				{ code: 'sk', title: 'Slovak' },
				{ code: 'bg', title: 'Bulgarian' },
			],
			isDropdownVisible: {
				account: false,
				language: false,
			},
		};
	},
	components: {
		IconButton,
	},
	methods: {
		...mapMutations('me', [
			'setLanguage',
		]),
		...mapActions([
			'clearAll',
		]),
		logout() {
			// eslint-disable-next-line no-alert
			const really = window.confirm(this.$t('logoutConfirm'));
			if (really) {
				this.clearAll()
					.then(() => {
						this.$router.push({ name: 'Login' });
					});
			}
		},
		editAccount() {
			this.$router.push({ name: 'EditAccount' });
		},
		changeClient() {
			this.$router.push({ name: 'SelectClient' });
		},
		moveHome() {
			this.$router.push({ name: 'Crossroads' });
		},
		moveUpwards() {
			this.$router.go(-1);
		},
		openCalculator() {
			this.$modal.show('calculator');
			// window.open('https://www.desmos.com/scientific');
		},
		openPrint() {
			window.print();
		},
		openDrawing() {
			this.$router.push({ name: 'DrawingsCreate' });
		},
		hasButton(button) {
			return button.includes(this.$route.name);
		},
		dropdownToggle(dropdown) {
			const originalState = this.isDropdownVisible[dropdown];
			this.dropdownHide();
			this.isDropdownVisible[dropdown] = !originalState;
		},
		dropdownHide() {
			Object.keys(this.isDropdownVisible).forEach((key) => {
				this.isDropdownVisible[key] = false;
			});
		},
		switchLanguage(language) {
			this.setLanguage(language);
			this.$root.$i18n.locale = language;
		},
	},
	computed: {
		...mapGetters({
			isLoggedIn: 'me/isLoggedIn',
			hasCapability: 'me/hasCapability',
			isClientSelected: 'clients/isClientSelected',
		}),
		hasButtonMoveUpwards() {
			// eslint-disable-next-line max-len
			const match = Object.values(this.siteHierarchy).find((pagesList) => pagesList.find((pageName) => this.$route.name === pageName));
			return (typeof match !== 'undefined');
		},
		headerBadge() {
			let badge = {
				title: `API base: ${process.env.VUE_APP_API_BASE}`,
			};
			if (process.env.NODE_ENV === 'development') {
				badge.label = 'Dev';
				badge.color = '#EAA74A';
			} else if (process.env.VUE_APP_MODE === 'staging') {
				badge.label = 'Test';
				badge.color = '#AD63D5';
			} else {
				badge = null;
			}
			return badge;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/scss/global';

.the-header {
	margin: 0.5rem 1.25rem;
	border-radius: $border-radius;
	min-height: 3.25rem;
	background-color: $white;
	box-shadow: $input-shadow;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;

	.left {
		flex: 0 0 40%;
	}
	.center {
		flex: 0 0 20%;
		text-align: center;
		display: flex;
		justify-content: center;
	}
	.right {
		flex: 0 0 40%;
	}
	@media print {
		display: none;
	}
}

.header-logo {
	display: block;
	width: 90px;
	height: auto;
}

.header-badge {
	background-color: gray;
	color: white;
	border-radius: 0.25rem;
	padding: 0.5em 1em;
	margin-left: 10px;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 700;
	letter-spacing: 0.1em;
}

.header-items {
	display: flex;
	position: relative;
	&.right {
		justify-content: flex-end;
	}
}

.selected-client {
	overflow: hidden;
	display: flex;
	align-items: center;
	padding: 0 1.25rem;
	border-left: 1px solid $gray-400;
	margin-left: -1px;
	text-transform: uppercase;
	font-size: 0.75rem;
	* {
		vertical-align: middle;
	}
	.label {
		margin-right: 0.25em;
	}
	.name {
		@include reset-link;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.caret {
		font-size: 0.75em;
		margin-left: 0.5em;
	}
}

.dropdown-menu {
	border-radius: $border-radius;
	position: absolute;
	right: 0;
	top: 110%;
	background-color: $white;
	border: 1px solid $gray-200;
	box-shadow: $input-shadow;
	min-width: 10rem;
	button {
		@include reset-button();
		display: block;
		width: 100%;
		text-align: center;
		padding: 0.75rem;
		text-align: center;
		font-size: 0.75rem;
		border-bottom: 1px solid $gray-300;
		text-transform: uppercase;
		color: $gray-500;
		font-weight: 700;
		letter-spacing: 0.1em;
	}
	button:hover {
		background-color: $gray-100;
	}
}

</style>

<i18n>
{
	"en": {
		"myAccount": "My account",
		"calculator": "Calculator",
		"logout": "Log out",
		"logoutConfirm": "Are you sure you want to log out?"
	},
	"sk": {
		"myAccount": "Môj účet",
		"calculator": "Kalkulačka",
		"logout": "Odhlásiť sa",
		"logoutConfirm": "Skutočne sa chcete odhlásiť?"
	},
	"bg": {
		"myAccount": "Моят акаунт",
		"calculator": "Калкулатор",
		"logout": "Изход",
		"logoutConfirm": "Сигурни ли сте, че искате да излезнете от акаунта си?"
	}
}
</i18n>
