import api from '@/api/';

const initialState = {
	list: [],
};

export default {
	namespaced: true,
	state: { ...initialState },
	mutations: {
		setUsers(state, payload) {
			state.list = payload;
		},
		clearState(state) {
			Object.assign(state, initialState);
		},
	},
	actions: {
		fetchUsers({ commit }) {
			return api.request({
				url: '/users/',
				method: 'get',
			})
				.then(({ data }) => {
					commit('setUsers', data);
					return true;
				})
				.catch(() => false);
		},
		createUser({ dispatch }, data) {
			return api.request({
				url: '/users/',
				method: 'post',
				data,
			})
				.then(() => {
					dispatch('fetchUsers');
					return true;
				})
				.catch(() => false);
		},
		updateUser({ dispatch }, user) {
			const data = {};
			if (user.password) data.password = user.password;
			if (user.role) data.role = user.role;
			if (user.language) data.language = user.language;

			return api.request({
				url: `/users/${user.id}`,
				method: 'put',
				data,
			})
				.then(() => {
					dispatch('fetchUsers');
					return true;
				})
				.catch(() => false);
		},

		deleteUser({ dispatch }, userId) {
			return api.request({
				url: `/users/${userId}`,
				method: 'delete',
			})
				.then(() => {
					dispatch('fetchUsers');
					return true;
				})
				.catch(() => false);
		},

		getTeamMembers(context, userId) {
			return api.request({
				url: `/users/${userId}/team-members/`,
				method: 'get',
			})
				.then(({ data }) => data)
				.catch(() => false);
		},
		setTeamMembers(context, payload) {
			const { userId, members } = payload;
			return api.request({
				url: `/users/${userId}/team-members/`,
				method: 'post',
				data: {
					users: members,
				},
			})
				.then(() => true)
				.catch(() => false);
		},
	},
	getters: {
		getUserById: (state) => (userId) => {
			const match = state.list.find((user) => user.id === userId);
			return match;
		},
		getUserEmailById: (state, getters) => (userId) => {
			const user = getters.getUserById(userId);
			return user ? user.email : null;
		},
	},
};
