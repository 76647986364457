import api from '@/api/';
import {
	formatISO, parseISO, endOfYear, startOfYear,
} from 'date-fns';

const initialState = {
	events: [],
};

function adaptEventFromSource(source) {
	const editable = (!source.id.toString().includes('-'));
	return {
		...source,
		classNames: editable ? ['-editable'] : ['-non-editable'],
		start: parseISO(source.start),
		end: parseISO(source.end),
	};
}

export default {
	namespaced: true,
	state: { ...initialState },
	mutations: {
		setEvents(state, events) {
			state.events = events;
		},

		removeEvent(state, id) {
			const index = state.events.findIndex((item) => item.id === id);
			state.events.splice(index, 1);
		},
		addEvent(state, event) {
			state.events.push(event);
		},
		updateEvent(state, event) {
			const index = state.events.findIndex((item) => item.id === event.id);
			state.events.splice(index, 1, event);
		},
		clearState(state) {
			Object.assign(state, initialState);
		},
	},
	actions: {
		updateEvent({ commit }, event) {
			return api.request({
				url: `/calendar/events/${event.id}`,
				method: 'put',
				data: event,
			})
				.then(({ data }) => {
					commit('updateEvent', adaptEventFromSource(data));
					return data;
				});
		},
		updateEventSilently({ commit }, event) {
			return api.request({
				url: '/calendar/events/',
				method: 'patch',
				data: event,
			})
				.then(({ data }) => {
					commit('updateEvent', adaptEventFromSource(data));
					return data;
				});
		},
		createEvent({ commit }, event) {
			return api.request({
				url: '/calendar/events/',
				method: 'post',
				data: event,
			})
				.then(({ data }) => {
					commit('addEvent', adaptEventFromSource(data));
					return data;
				});
		},
		deleteEvent({ commit }, eventId) {
			return api.request({
				url: `/calendar/events/${eventId}`,
				method: 'delete',
			})
				.then(({ data }) => {
					commit('removeEvent', eventId);
					return data;
				});
		},

		fetchEvents({ commit }, payload) {
			const defaultDatespan = {
				from: startOfYear(new Date()),
				to: endOfYear(new Date()),
			};
			const datespan = payload || defaultDatespan;

			datespan.from = formatISO(datespan.from, { representation: 'date' });
			datespan.to = formatISO(datespan.to, { representation: 'date' });

			return api.request({
				url: '/calendar/events/',
				method: 'get',
				params: datespan,
			})
				.then(({ data }) => {
					const parsedEvents = data.map(adaptEventFromSource);
					commit('setEvents', parsedEvents);
					return true;
				});
		},
		fetchFeed() {
			return api.request({
				url: '/calendar/events/export.ics',
				method: 'get',
			})
				.then(() => {
					// console.log(response)
				});
		},
	},
	getters: {
	},
};
