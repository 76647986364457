import api from '@/api/';
import i18n from '@/i18n';

const initialState = {
	list: [],
	categoryStructure: {
		brochures: false,
		contracts: false,
		specialistDocuments: false,
		performanceReports: false,
	},
};

export default {
	namespaced: true,
	state: { ...initialState },
	mutations: {
		addDocument(state, payload) {
			state.list.push(payload);
		},
		removeDocument(state, id) {
			const index = state.list.findIndex((doc) => doc.id === id);
			state.list.splice(index, 1);
		},
		updateDocument(state, payload) {
			const index = state.list.findIndex((doc) => doc.id === payload.id);
			state.list[index] = payload.data;
		},
		setDocuments(state, payload) {
			state.list = payload;
		},
		setCategories(state, payload) {
			Object.assign(state.categoryStructure, payload);
		},
		clearState(state) {
			Object.assign(state, initialState);
		},
	},
	actions: {
		createDocument({ commit }, payload) {
			const cleanPayload = {
				...payload,
				order: payload.order === '' ? null : parseInt(payload.order, 10),
			};
			return api.request({
				url: '/documents/',
				method: 'post',
				data: cleanPayload,
				timeout: 15000,
			})
				.then(({ data }) => {
					commit('addDocument', data);
					return true;
				})
				.catch(() => false);
		},
		deleteDocument({ commit }, id) {
			return api.request({
				url: `/documents/${id}`,
				method: 'delete',
			})
				.then(() => {
					commit('removeDocument', id);
					return true;
				})
				.catch(() => false);
		},
		updateDocument({ dispatch }, payload) {
			const cleanPayload = {
				name: payload.name,
				category: payload.category,
				subcategory: payload.subcategory,
				order: payload.order === '' ? null : parseInt(payload.order, 10),
			};
			if (payload.file) cleanPayload.file = payload.file;
			return api.request({
				url: `/documents/${payload.editingId}`,
				method: 'put',
				data: cleanPayload,
				timeout: 15000,
			})
				.then(() => {
					dispatch('fetchDocuments');
					return true;
				})
				.catch(() => false);
		},

		fetchDocuments({ commit }) {
			return api.request({
				url: '/documents/',
				method: 'get',
			})
				.then(({ data }) => {
					commit('setDocuments', data);
				});
		},
		fetchCategories({ commit }) {
			return api.request({
				url: '/documents/categories/',
				method: 'get',
			})
				.then(({ data }) => {
					commit('setCategories', data);
				});
		},
	},
	getters: {
		categoryMains: (state) => Object.keys(state.categoryStructure),
		categorySubs: (state) => (main) => state.categoryStructure[main],
		categoryOptions(state, getters) {
			const cats = getters.categoryMains;
			const options = cats.map((code) => ({
				value: code,
				label: i18n.t(`documentCategories.${code}`),
			}));
			options.unshift({ value: null, label: i18n.t('common.choose') });
			return options;
		},
		subcategoryOptions: (state, getters) => (main) => {
			const subs = getters.categorySubs(main);
			if (!subs || !subs.length) return null;
			const options = subs.map((code) => ({
				value: code,
				label: i18n.t(`documentCategories.${code}`),
			}));
			options.unshift({ value: null, label: i18n.t('common.choose') });
			return options;
		},
	},
};
