import api from '@/api/';
import initialClientDetails from '@/constants/initialClientDetails';
import cleanEmptyProps from '@/util/cleanEmptyProps';
import countries from '@/util/countries.json';
import i18n from '@/i18n';
import Vue from 'vue';

const getInitialState = () => ({
	selected: {
		id: 0,
		name: '',
		details: {},
		financials: {},
		photos: [],
		documents: [],
		relatives: [],
		signedProfiles: [],
		drawings: [],
	},
	list: [],
});

export default {
	namespaced: true,
	state: { ...getInitialState() },
	mutations: {
		setClient(state, payload) {
			state.selected.id = payload.id;
			state.selected.name = payload.middleName
				? `${payload.firstName} ${payload.middleName} ${payload.lastName}`
				: `${payload.firstName} ${payload.lastName}`;

			// Set client details
			state.selected.details = {
				...initialClientDetails(),
				...cleanEmptyProps(payload),
			};
			state.selected.details.address = {
				...payload.address,
			};
		},
		setClientFinancials(state, payload) {
			Vue.set(state.selected, 'financials', payload);
		},
		setClientPhotos(state, payload) {
			state.selected.photos = payload;
		},
		setClientDocuments(state, payload) {
			state.selected.documents = payload;
		},
		setClientRelatives(state, payload) {
			state.selected.relatives = payload;
		},
		setClientSignedDocuments(state, payload) {
			state.selected.signedDocuments = payload;
		},
		setClientDrawings(state, payload) {
			state.selected.drawings = payload;
		},
		setClientList(state, payload) {
			state.list = payload;
		},
		clearState(state) {
			Object.assign(state, getInitialState());
		},
	},
	actions: {
		createNewClient(context, newClient) {
			return api.request({
				url: '/clients/',
				method: 'post',
				data: {
					firstName: newClient.firstName,
					lastName: newClient.lastName,
				},
			})
				.then(({ data }) => data);
		},
		createAndSelectNewClient({ state, commit, dispatch }, newClient) {
			return dispatch('createNewClient', newClient)
				.then((theClient) => {
					const { list } = state;
					list.push(theClient);
					commit('setClientList', list);
					commit('setClient', theClient);
				});
		},
		selectExistingClient({ commit }, clientId) {
			return api.request({
				url: `/clients/${clientId}/details`,
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClient', data);
					return true;
				});
		},
		fetchClients({ commit }) {
			// TODO: Merge with fetchClientDetails
			return api.request({
				url: '/clients/',
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClientList', data);
				});
		},
		fetchClientDetails({ commit, state }) {
			return api.request({
				url: `/clients/${state.selected.id}/details`,
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClient', data);
					return true;
				});
		},
		fetchCustomClientDetails(context, clientId) {
			return api.request({
				url: `/clients/${clientId}/details`,
				method: 'get',
			})
				.then(({ data }) => data);
		},
		deleteClient({ dispatch }, clientId) {
			return api.request({
				url: `/clients/${clientId}`,
				method: 'delete',
			})
				.then(() => {
					dispatch('fetchClients');
					return true;
				});
		},

		updateClientDetails({ commit, state }, clientDetails) {
			const cleanClientDetails = cleanEmptyProps(clientDetails);

			return api.request({
				url: `/clients/${state.selected.id}/details`,
				method: 'patch',
				data: cleanClientDetails,
			})
				.then(({ data }) => {
					commit('setClient', data);
					return true;
				});
		},

		updateClientFinancials({ dispatch, state }, data) {
			const { type } = data;
			const { financials } = data;

			return api.request({
				url: `/clients/${state.selected.id}/financials/${type}`,
				method: 'put',
				data: financials,
			})
				.then(() => {
					dispatch('fetchClientFinancials');
					return true;
				});
		},
		fetchClientFinancials({ commit, state }) {
			return api.request({
				url: `/clients/${state.selected.id}/financials/`,
				method: 'get',
			})
				.then(({ data }) => {
					// TODO: Get clean from backend
					const clean = {
						assets: data.assets,
						bankAccounts: data.bankAccounts,
						earnings: data.earnings,
						expenses: data.expenses,
						insurances: data.insurances,
						loans: data.loans,
						securities: data.securities,
					};
					commit('setClientFinancials', clean);
					return true;
				});
		},

		postClientPhoto({ state, dispatch }, photo) {
			return api.request({
				url: `/clients/${state.selected.id}/photos/`,
				method: 'post',
				data: photo,
			})
				.then(() => {
					dispatch('fetchClientPhotos');
					return true;
				});
		},
		deleteClientPhoto({ state, dispatch }, photoId) {
			return api.request({
				url: `/clients/${state.selected.id}/photos/${photoId}`,
				method: 'delete',
			})
				.then(() => {
					dispatch('fetchClientPhotos');
					return true;
				});
		},
		fetchClientPhotos({ commit, state }) {
			return api.request({
				url: `/clients/${state.selected.id}/photos/`,
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClientPhotos', data.photos);
					return true;
				});
		},
		postClientDocument({ state, dispatch }, document) {
			return api.request({
				url: `/clients/${state.selected.id}/documents/`,
				method: 'post',
				data: document,
			})
				.then(() => {
					dispatch('fetchClientDocuments');
					return true;
				});
		},
		deleteClientDocument({ state, dispatch }, documentId) {
			return api.request({
				url: `/clients/${state.selected.id}/documents/${documentId}`,
				method: 'delete',
			})
				.then(() => {
					dispatch('fetchClientDocuments');
					return true;
				});
		},
		fetchClientDocuments({ commit, state }) {
			return api.request({
				url: `/clients/${state.selected.id}/documents/`,
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClientDocuments', data);
					return true;
				});
		},
		fetchClientRelatives({ commit, state }) {
			return api.request({
				url: `/clients/${state.selected.id}/relatives/`,
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClientRelatives', data);
					return true;
				});
		},
		updateClientRelatives({ state }, relatives) {
			return api.request({
				url: `/clients/${state.selected.id}/relatives/`,
				method: 'put',
				data: relatives,
			})
				.then(() => true);
		},

		// updateClientSignature({ state, rootState }, signature) {
		// 	return api.request({
		// 		url: `/clients/${state.selected.id}/signature`,
		// 		method: 'put',
		// 		data: signature,
		// 	})
		// 		.then(() => {
		// 			return true
		// 		})
		// },
		// fetchClientSignedDocuments({ commit, state, rootState }) {
		// 	return api.request({
		// 		url: `/clients/${state.selected.id}/signed-documents/`,
		// 		method: 'get',
		// 	})
		// 		.then(({data}) => {
		// 			commit('setClientSignedDocuments', data)
		// 			return true
		// 		})
		// },
		createClientSignedDocument({ state, dispatch }, payload) {
			const { type = 'SUMMARY', html } = payload;
			return api.request({
				url: `/clients/${state.selected.id}/signed-documents/${type}`,
				method: 'post',
				data: html,
				headers: {
					'Content-Type': 'text/html',
				},
			})
				.then(() => {
					dispatch('fetchClientSignedDocuments');
					return true;
				});
		},
		fetchClientSignedDocuments({ commit, state }) {
			return api.request({
				url: `/clients/${state.selected.id}/signed-documents/`,
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClientSignedDocuments', data.signedDocuments);
					return true;
				});
		},
		createClientDrawing({ state }, payload) {
			return api.request({
				url: `/clients/${state.selected.id}/drawings/`,
				method: 'post',
				data: {
					name: 'drawing',
					file: payload.file,
				},
			})
				.then(({ data }) => data.id);
		},
		updateClientDrawing({ state }, payload) {
			return api.request({
				url: `/clients/${state.selected.id}/drawings/${payload.id}`,
				method: 'put',
				data: {
					name: 'drawing',
					file: payload.file,
				},
			})
				.then(({ data }) => data.id);
		},
		deleteClientDrawing({ state, dispatch }, drawingId) {
			return api.request({
				url: `/clients/${state.selected.id}/drawings/${drawingId}`,
				method: 'delete',
			})
				.then(() => {
					dispatch('fetchClientDrawings');
					return true;
				});
		},
		fetchClientDrawings({ commit, state }) {
			return api.request({
				url: `/clients/${state.selected.id}/drawings/`,
				method: 'get',
			})
				.then(({ data }) => {
					commit('setClientDrawings', data);
					return true;
				});
		},

	},
	getters: {
		isClientSelected(state) {
			return state.selected.id !== 0;
		},
		getClientById: (state) => (clientId) => {
			const match = state.list.find((client) => client.id === clientId);
			return match;
		},
		selectedClientFullName(state) {
			return `${state.selected.details.firstName} ${state.selected.details.middleName} ${state.selected.details.lastName}`;
		},
		clientListOptions(state) {
			return state.list.map((client) => ({
				value: client.id,
				label: `${client.firstName} ${client.middleName} ${client.lastName}`,
			}));
		},
		typesOfFinancials() {
			return {
				earnings: {
					additionalFields: ['monthlyAmount', 'education'],
					categories: [
						{ value: 'employment', label: i18n.t('clientProfile.financials.employment') },
						{ value: 'rental', label: i18n.t('clientProfile.financials.rental') },
						{ value: 'pension', label: i18n.t('clientProfile.financials.pension') },
						{ value: 'enterprise', label: i18n.t('clientProfile.financials.enterprise') },
						{ value: 'socialSecurity', label: i18n.t('clientProfile.financials.socialSecurity') },
						{ value: 'other', label: i18n.t('clientProfile.financials.other') },
					],
				},
				expenses: {
					additionalFields: ['monthlyAmount'],
					categories: [
						{ value: 'household', label: i18n.t('clientProfile.financials.household') },
						{ value: 'car', label: i18n.t('clientProfile.financials.transport') },
						{ value: 'leisure', label: i18n.t('clientProfile.financials.leisure') },
						{ value: 'food', label: i18n.t('clientProfile.financials.food') },
						{ value: 'cosmeticsAndClothes', label: i18n.t('clientProfile.financials.cosmeticsAndClothes') },
						{ value: 'education', label: i18n.t('clientProfile.financials.education') },
						{ value: 'vacations', label: i18n.t('clientProfile.financials.vacations') },
						{ value: 'other', label: i18n.t('clientProfile.financials.other') },
					],
				},
				assets: {
					additionalFields: ['totalAmount'],
					categories: [
						{ value: 'realEstate', label: i18n.t('clientProfile.financials.realEstate') },
						{ value: 'car', label: i18n.t('clientProfile.financials.car') },
						{ value: 'businessEquity', label: i18n.t('clientProfile.financials.businessEquity') },
						{ value: 'cash', label: i18n.t('clientProfile.financials.cash') },
						{ value: 'other', label: i18n.t('clientProfile.financials.other') },
					],
				},
				insurances: {
					additionalFields: ['yearlyAmount', 'anniversaryDate'],
					categories: [
						{ value: 'lifeInsurance', label: i18n.t('clientProfile.financials.lifeInsurance') },
						{ value: 'nonLifeInsurance', label: i18n.t('clientProfile.financials.nonLifeInsurance') },
						{ value: 'other', label: i18n.t('clientProfile.financials.other') },
					],
				},
				loans: {
					additionalFields: ['totalAmount', 'period', 'interest', 'interestFixationDate', 'monthlyPayment', 'startingDate', 'leftToPay', 'collateralRealEstate', 'notes'],
					categories: [
						{ value: 'consumerCredit', label: i18n.t('clientProfile.financials.consumerCredit') },
						{ value: 'mortgage', label: i18n.t('clientProfile.financials.mortgage') },
						{ value: 'creditCard', label: i18n.t('clientProfile.financials.creditCard') },
						{ value: 'leasing', label: i18n.t('clientProfile.financials.leasing') },
						{ value: 'constructionLoan', label: i18n.t('clientProfile.financials.constructionLoan') },
						{ value: 'other', label: i18n.t('clientProfile.financials.other') },
					],
				},
				securities: {
					additionalFields: ['totalAmount', 'performance'],
					categories: [
						{ value: 'oneTimeInvestment', label: i18n.t('clientProfile.financials.oneTimeInvestment') },
						{ value: 'bond', label: i18n.t('clientProfile.financials.bond') },
						{ value: 'stock', label: i18n.t('clientProfile.financials.stock') },
						{ value: 'derivative', label: i18n.t('clientProfile.financials.derivative') },
						{ value: 'other', label: i18n.t('clientProfile.financials.other') },
					],
				},
				bankAccounts: {
					additionalFields: ['totalAmount', 'interest', 'regularIncrease'],
					categories: [
						{ value: 'standardAccount', label: i18n.t('clientProfile.financials.standardAccount') },
						{ value: 'savingsAccount', label: i18n.t('clientProfile.financials.savingsAccount') },
						{ value: 'termDeposit', label: i18n.t('clientProfile.financials.termDeposit') },
						{ value: 'constructionSavings', label: i18n.t('clientProfile.financials.constructionSavings') },
						{ value: 'regularFund', label: i18n.t('clientProfile.financials.regularFund') },
						{ value: 'other', label: i18n.t('clientProfile.financials.other') },
					],
				},
			};
		},
		financialsSections() {
			return [
				{ value: 'assets', label: i18n.t('clientProfile.financials.assets') },
				{ value: 'insurances', label: i18n.t('clientProfile.financials.insurances') },
				{ value: 'loans', label: i18n.t('clientProfile.financials.loans') },
				{ value: 'securities', label: i18n.t('clientProfile.financials.securities') },
				{ value: 'bankAccounts', label: i18n.t('clientProfile.financials.bankAccounts') },
				{ value: 'earnings', label: i18n.t('clientProfile.financials.earnings') },
				{ value: 'expenses', label: i18n.t('clientProfile.financials.expenses') },
			];
		},
		translateFinancialSection: (state, getters) => (query) => {
			const match = getters.financialsSections.find((sec) => sec.value === query);
			if (match) {
				return match.label;
			}
			return null;
		},
		translateFinancialCategory: (state, getters) => (query) => {
			const type = query[0];
			const category = query[1];
			const { categories } = getters.typesOfFinancials[type];
			const match = categories.find((cat) => cat.value === category);
			if (match) {
				return match.label;
			}
			return category;
		},
		photoCategories() {
			return [
				{ value: 'client', label: i18n.t('clientProfile.photoCategories.client') },
				{ value: 'product', label: i18n.t('clientProfile.photoCategories.product'), askForAdditional: true },
				{ value: 'personalDocuments', label: i18n.t('clientProfile.photoCategories.personalDocuments') },
			];
		},
		countriesOptions() {
			return countries.map((country) => ({
				value: country.code,
				label: country.name,
			}));
		},
	},
};
