import api from '@/api/';

function adaptRecommendationFromSource(source) {
	return {
		id: source.id,
		from: source.recommender,
		processed: source.processed,
		notes: source.notes,
		to: {
			name: source.name,
			email: source.email,
			phone: source.phone,
			job: source.job,
			relationToRecommender: source.relationToRecommender,
			age: source.age,
		},
	};
}

const initialState = {
	list: [],
};

export default {
	namespaced: true,
	state: { ...initialState },
	mutations: {
		addRecommendation(state, payload) {
			state.list.unshift(payload);
		},
		removeRecommendation(state, id) {
			const index = state.list.findIndex((recom) => recom.id === id);
			state.list.splice(index, 1);
		},
		updateRecommentation(state, payload) {
			const index = state.list.findIndex((recom) => recom.id === payload.id);
			state.list[index].processed = payload.processed;
		},
		setRecommendations(state, payload) {
			state.list = payload;
		},
		clearState(state) {
			Object.assign(state, initialState);
		},
	},
	actions: {
		createRecommendation({ commit, rootState }, payload) {
			return api.request({
				url: '/recommendations/',
				method: 'post',
				data: {
					name: payload.to.name,
					phone: payload.to.phone,
					email: payload.to.email,
					age: parseInt(payload.to.age),
					job: payload.to.job,
					relationToRecommender: payload.to.relationToRecommender,
					notes: payload.to.notes,
					recommender: rootState.clients.selected.id,
				},
				timeout: 30000,
			})
				.then(({ data }) => {
					const transformed = adaptRecommendationFromSource(data);
					commit('addRecommendation', transformed);
					return true;
				})
				.catch(() => false);
		},
		updateRecommendation({ dispatch }, payload) {
			return api.request({
				url: `/recommendations/${payload.id}`,
				method: 'patch',
				data: {
					name: payload.name,
					phone: payload.phone,
					email: payload.email,
					age: parseInt(payload.age),
					job: payload.job,
					relationToRecommender: payload.relationToRecommender,
					notes: payload.notes,
				},
			})
				.then(() => {
					dispatch('fetchRecommendations');
					return true;
				})
				.catch(() => false);
		},

		deleteRecommendation({ commit }, id) {
			return api.request({
				url: `/recommendations/${id}`,
				method: 'delete',
			})
				.then(() => {
					commit('removeRecommendation', id);
					return true;
				})
				.catch(() => false);
		},
		markRecommendation({ commit }, payload) {
			return api.request({
				url: `/recommendations/${payload.id}`,
				method: 'patch',
				data: {
					processed: payload.processed,
				},
			})
				.then(({ data }) => {
					commit('updateRecommentation', {
						id: payload.id,
						processed: data.recommendation.processed,
					});
					return true;
				})
				.catch(() => false);
		},

		fetchRecommendations({ commit }) {
			return api.request({
				url: '/recommendations/',
				method: 'get',
			})
				.then((response) => {
					const transformed = response.data.map(adaptRecommendationFromSource);
					commit('setRecommendations', transformed);
				});
		},
	},
};
