import api from '@/api/';

const initialState = {
	name: '',
	email: '',
	token: '',
	language: '',
	frontendLanguage: '',
	role: 'unauthorized',
	teamMembers: [],
};

export default {
	namespaced: true,
	state: { ...initialState },
	mutations: {
		setToken(state, token) {
			state.token = token;
		},
		setMe(state, payload) {
			state.name = payload.name;
			state.email = payload.email;
			state.language = payload.language;
			state.role = payload.role;
		},
		setTeamMembers(state, payload) {
			state.teamMembers = payload;
		},
		setLanguage(state, payload) {
			state.frontendLanguage = payload;
		},
		clearState(state) {
			Object.assign(state, initialState);
		},
	},
	actions: {
		login({ commit, dispatch }, payload) {
			return api.request({
				url: '/users/login',
				method: 'post',
				data: {
					email: payload.email,
					password: payload.password,
				},
			})
				.then(({ data }) => {
					commit('setToken', data.token);
					commit('setMe', { role: 'before_role_loaded' });
					dispatch('clients/fetchClients', {}, { root: true });
					return dispatch('fetchMe');
				})
				.catch(() => false);
		},
		sendResetPasswordLink(ctx, payload) {
			return api.request({
				url: '/users/send-reset-link',
				method: 'post',
				data: {
					email: payload.email,
				},
			})
				.then(() => true)
				.catch(() => false);
		},
		resetPassword(ctx, payload) {
			return api.request({
				url: '/users/reset-password',
				method: 'post',
				data: {
					token: payload.token,
					password: payload.password,
				},
			})
				.then(() => true)
				.catch(() => false);
		},

		fetchMe({ commit }) {
			return api.request({
				url: '/users/me',
				method: 'get',
			})
				.then(({ data }) => {
					commit('setMe', data);
					return true;
				})
				.catch(() => false);
		},
		updateMe(context, payload) {
			return api.request({
				url: '/users/me',
				method: 'put',
				data: {
					...payload,
				},
			})
				.then(() => true);
		},
		fetchMyTeamMembers({ commit }) {
			return api.request({
				url: '/users/me/team-members/',
				method: 'get',
			})
				.then(({ data }) => {
					commit('setTeamMembers', data);
					return true;
				})
				.catch(() => false);
		},
	},
	getters: {
		isLoggedIn(state) {
			return state.role !== 'unauthorized';
		},
		getCapabilities(state) {
			const caps = {
				unauthorized: [],
				user: ['clients.self', 'reports.self', 'recommendations.self', 'calendar.self', 'documents.read'],
				backoffice: ['clients.self', 'clients.all', 'reports.self', 'recommendations.self', 'calendar.self', 'documents.read'],
				admin: ['clients.self', 'clients.all', 'reports.self', 'recommendations.self', 'calendar.self', 'documents.read', 'documents.write', 'users.manage'],
			};
			return caps[state.role];
		},
		hasCapability: (state, getters) => (cap) => getters.getCapabilities.includes(cap),
	},
};
