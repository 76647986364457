const getInitialClientDetails = () => ({
	firstName: '',
	lastName: '',
	identificationNumber: '',
	idCardNumber: '',
	socialSecurityDescription: '',
	familyStatus: '',

	email: '',
	phoneNumber: '',
	address: {
		street: '',
		town: '',
		zipCode: '',
	},
	correspondenceAddress: {
		street: '',
		town: '',
		zipCode: '',
	},

	employer: '',
	jobTitle: '',
	education: '',
	goals: '',
});

export default getInitialClientDetails;
