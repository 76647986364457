import Vue from 'vue';

import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store from './store';
import router from './router';
import i18n from './i18n';
import App from './App.vue';

Vue.use(Toasted, {
	duration: 4000,
	iconPack: 'fontawesome',
	position: 'bottom-right',

});
Vue.use(VModal);

library.add(fas);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#ewem-app');
